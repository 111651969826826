/**
 * Если мы уже пушили Top Banner, то больше пушить его
 * не нужно при переходах по страницам
 * @param {*} payload
 */
export const checkTopBanner = (payload) => {
  const dataLayer = [...window.dataLayer];
  return dataLayer.some((event) => {
    const currentEvent = event?.ecommerce?.items[0];
    if (currentEvent) {
      if (currentEvent.creative_slot === 'top-banner'
      && currentEvent.promotion_id === Number(payload.id)) {
        return true;
      }
      return false;
    } return false;
  });
};
