import { captureException } from '@sentry/browser';

import useHttpClient from '@/compositions/useHttpClient';

const useClickRecorder = () => {
  const httpClient = useHttpClient();

  const sendClick = async () => {
    await httpClient.partner.clickRecorder()
      .catch(error => captureException(error));
  };

  return { sendClick };
};

export default useClickRecorder;
