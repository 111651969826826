import { computed, useRoute, watch } from '@nuxtjs/composition-api';

import { checkMobile } from '@/utils/deviceCheck';
import loadScript from '@/utils/loadScript';

const useJivoSite = () => {
  const route = useRoute();
  const isMobile = checkMobile();
  const widgetId = 'X1I9Jp9tMk';
  const jivositeText = 'Напишите нам, мы онлайн!';
  const jivositeLoading = 'Загрузка...';
  const jivositeClassStub = 'fake-jivosite';
  const url = `//code.jivosite.com/script/widget/${widgetId}`;

  const isRenderJivoWidget = computed(() => !route.value.path.includes('/personal/')
    && !route.value.path.includes('/book/')
    && !route.value.path.includes('/bookqr/')
    && !isMobile);

  const isMobileContactsPage = computed(() => route.value.path.includes('/contacts/') && isMobile);

  // Создаем и встраиваем кнопку JivoSite
  const createFakeJivoButton = () => {
    const fakeJivoButton = document.createElement('div');
    fakeJivoButton.classList.add(jivositeClassStub);
    fakeJivoButton.innerText = jivositeText;
    document.body.insertBefore(fakeJivoButton, document.body.firstChild);
  };

  // Скрываем кнопку JivoSite и сбрасываем на ней текст
  const hideFakeJivoButton = (fakeButton) => {
    if (!fakeButton) return;
    fakeButton.innerText = jivositeText;
    fakeButton.style.display = 'none';
  };

  // Удаляем кнопку JivoSite
  const destroyFakeJivoButton = (fakeButton) => {
    if (!fakeButton) return;
    fakeButton.remove();
    if (window.jivo_api) window.jivo_destroy();
  };

  // Загружаем скрипт JivoSite
  const loadJivoSite = (fakeButton) => {
    if (!fakeButton) return;
    fakeButton.innerText = jivositeLoading;
    loadScript(url);
  };

  // Встраиваем JivoSite на страницу
  const insertJivoSite = () => {
    createFakeJivoButton();

    const fakeJivoButton = document.getElementsByClassName(jivositeClassStub)[0];

    fakeJivoButton.addEventListener('click', () => {
      if (!window.jivo_api) {
        loadJivoSite(fakeJivoButton);
      } else window.jivo_init();

      window.jivo_onLoadCallback = () => {
        hideFakeJivoButton(document.getElementsByClassName(jivositeClassStub)[0]);
        // eslint-disable-next-line no-undef
        jivo_api.open();
      };
    });
  };

  // Встраиваем JivoSite на странице Контакты на моб
  const insertJivoSiteMobile = () => {
    const $jivoSiteCallEl = document.querySelector('.js-call-jivosite');
    loadScript(url);

    if ($jivoSiteCallEl) {
      window.jivo_onLoadCallback = () => {
        $jivoSiteCallEl.addEventListener('click', () => {
          // eslint-disable-next-line no-undef
          jivo_api.open();
        });
      };
    }
  };

  watch(() => isMobileContactsPage.value,
    (value) => {
      if (value && !window.jivo_api) insertJivoSiteMobile();
    });

  const initJivoSite = () => {
    if (!process.client) return;
    if (isRenderJivoWidget.value) {
      insertJivoSite();
      return;
    }

    if (isMobileContactsPage.value) insertJivoSiteMobile();
    else destroyFakeJivoButton(document.getElementsByClassName(jivositeClassStub)[0]);
  };

  watch(() => isRenderJivoWidget.value,
    () => initJivoSite(), { immediate: true });
};

export default useJivoSite;
