import { onUnmounted } from '@nuxtjs/composition-api';

import { useDebounceFn } from '@vueuse/core';
import { analyticViewSnippet } from '@/utils/analytics/analyticEvents';
import ElementsObserver from '@/utils/analytics/elementsObserver';

export const useViewedSnippets = () => {
  let viewObserver = null;
  let snippetViewed = [];

  const sendAnalyticSnippetsEvent = useDebounceFn(() => {
    analyticViewSnippet(snippetViewed);
    snippetViewed = [];
  }, 1000);

  const sendViewedSnippets = () => {
    viewObserver = new ElementsObserver({
      selector: '[data-b24-snippet-title]',
      wrapper: '[data-b24-analytic-wrapper]',
      event: 'snippet-view',
      threshold: 0.55,
    });

    viewObserver.init((payload) => {
      snippetViewed.push(payload);
      sendAnalyticSnippetsEvent();
    });
  };

  const reInitObserve = () => {
    if (viewObserver) {
      viewObserver.disconnectAll();
      sendViewedSnippets();
    }
  };

  onUnmounted(() => {
    if (viewObserver) {
      viewObserver.disconnectAll();
    }
  });

  return {
    sendViewedSnippets,
    reInitObserve,
  };
};
