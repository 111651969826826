export const setRobotsTag = (route) => {
  const { params } = route;
  const content = params.pageNumber > 1 ? 'noindex' : 'index';

  return {
    hid: 'robots',
    name: 'robots',
    content: `${content}, follow`,
  };
};
