import {
  onMounted,
  onErrorCaptured,
  useContext,
  useMeta,
  useRoute,
  computed,
  useStore,
  watch,
  nextTick,
} from '@nuxtjs/composition-api';

import { captureException } from '@sentry/browser';
import { checkMobile } from '@/utils/deviceCheck';
import { formattedCanonicalLink } from '@/utils/formatters/formattedCanonicalLink';
import { analyticStart, analyticViewOther } from '@/utils/analytics/analyticEvents';

import useJivoSite from '@/compositions/layout/useJivoSite';
import { useViewedBanners } from '@/compositions/analytics/useViewedBanners';
import { useViewedSnippets } from '@/compositions/analytics/useViewedSnippets';
import { useViewedProducts } from '@/compositions/analytics/useViewedProducts';
import { setRobotsTag } from '@/utils/setRobotsMeta';

const useLayoutDefault = () => {
  const context = useContext();
  const route = useRoute();
  const store = useStore();
  const { sendViewedBanners } = useViewedBanners();
  const { sendViewedSnippets } = useViewedSnippets();
  const { sendViewedProducts } = useViewedProducts();

  useJivoSite();

  const isMobile = checkMobile();

  const linkMeta = computed(() => {
    const { params } = route.value;
    if (params.pageNumber && Number(params.pageNumber) > 1) return [];
    return [{
      hid: 'canonical',
      rel: 'canonical',
      href: `${context.$config.appUrl}${route.value.name === 'main' ? '/' : formattedCanonicalLink(route.value)}`,
    }];
  });

  useMeta(() => ({
    meta: [
      { hid: 'og:url', property: 'og:url', content: `https://book24.ru${route.value.fullPath}` },
      setRobotsTag(route.value),
    ],
    link: linkMeta.value,
    script: [
      {
        src: `https://www.google.com/recaptcha/api.js?render=${context.$config.appGoogleRecaptchaPublicKeyV3}&hl=ru`,
        async: true,
      },
      {
        src: '/_ssr/js/pwa-prevent.js?v=1',
        async: true,
      },
    ],
  }));

  const isAuthorized = computed({
    get: () => store.state.isAuthorized,
    set: () => {},
  });

  onMounted(async () => {
    if (isMobile) sendViewedBanners();

    sendViewedSnippets();
    sendViewedProducts();
  });

  watch(() => route.value,
    (value) => {
      if (!process.client) return;
      analyticViewOther(value, false, false);
    }, { immediate: true });

  watch(() => store.state.user.email,
    async (value) => {
      if (value) {
        await nextTick();
        analyticStart(store.state.user);
      }
    });

  onErrorCaptured((error) => {
    captureException(error);
  });

  return {
    isAuthorized,
  };
};

export default useLayoutDefault;
