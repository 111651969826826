import { onUnmounted } from '@nuxtjs/composition-api';

import { useDebounceFn } from '@vueuse/core';
import { analyticViewPromotion } from '@/utils/analytics/analyticEvents';
import ElementsObserver from '@/utils/analytics/elementsObserver';
import { checkTopBanner } from '@/utils/analytics/checkTopBanner';

export const useViewedBanners = () => {
  let viewObserver = null;
  let bannersViewed = [];

  const sendAnalyticBannersEvent = useDebounceFn(() => {
    analyticViewPromotion(bannersViewed);
    bannersViewed = [];
  }, 1000);

  const sendViewedBanners = () => {
    viewObserver = new ElementsObserver({
      selector: '[data-b24-banner-name]',
      wrapper: '[data-b24-analytic-wrapper]',
      event: 'banner-view',
      threshold: 0.55,
    });

    viewObserver.init((payload) => {
      if (checkTopBanner(payload)) return;
      bannersViewed.push(payload);
      sendAnalyticBannersEvent();
    });
  };

  const reInitObserve = () => {
    if (viewObserver) {
      viewObserver.disconnectAll();
      sendViewedBanners();
    }
  };

  onUnmounted(() => {
    if (viewObserver) {
      viewObserver.disconnectAll();
    }
  });

  return {
    sendViewedBanners,
    reInitObserve,
  };
};
