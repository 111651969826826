<template>
  <div
    v-if="isAuthorized && fullName && email"
    class="i-flocktory"
    :data-fl-user-name="fullName"
    :data-fl-user-email="email"
  ></div>
</template>

<script>
import { computed, defineComponent, useStore } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AppFlocktorySetup',
  setup() {
    const { state } = useStore();

    const fullName = computed(() => `${state.user.firstName} ${state.user.lastName}`);
    const email = computed(() => state.user.email);
    const isAuthorized = computed(() => state.isAuthorized);

    return { isAuthorized, fullName, email };
  },
});
</script>
