import { setShelfIndexAttribute } from '@/utils/analytics/setShelfIndexAttribute';
import { getPropertiesFromAttributes } from './getPropertiesFromAttributes';

export default class ElementsObserver {
  constructor(config) {
    this.options = {
      rootMargin: '0px',
      threshold: config.threshold,
    };
    // опции - нужный атрибут для идентификации элемента
    // selector - селектор, по которому идентифицируются показанные элементы
    // threshold - доля элемента, при показе которой засчитывается показ
    // wrapper - селектор блока в котором будет происходить сбор элементов
    this.config = config;
    this.iObserver = null;
    this.mObserver = null;
  }

  init(callback) {
    const iObserveCallback = (entries) => {
      entries.forEach((entry) => {
        const { target, intersectionRatio } = entry;

        let shownElement;

        if (this.config.event === 'snippet-view') {
          const title = target.dataset.b24SnippetTitle;
          const index = target.dataset.b24SnippetIndex;
          const names = target.dataset.b24SnippetNames;
          const links = target.dataset.b24SnippetLinks;

          shownElement = {
            title,
            index,
            names: names.split('|'),
            links: links.split('|'),
          };
        }

        if (this.config.event === 'banner-view') {
          const name = target.dataset.b24BannerName;
          const slot = target.dataset.b24BannerSlot;
          const index = target.dataset.b24BannerIndex;
          const id = target.dataset.b24BannerId;
          const link = target.dataset.b24BannerLink;

          shownElement = {
            name,
            slot,
            index,
            id,
            link,
          };
        }

        if (this.config.event === 'product-view') {
          setShelfIndexAttribute();
          shownElement = getPropertiesFromAttributes(target);
        }

        if (!target || intersectionRatio < this.options.threshold) return;

        callback(shownElement);
        this.iObserver.unobserve(target);
      });
    };

    const setObserverToElements = (elements) => {
      elements.forEach((element) => {
        this.iObserver.observe(element);
      });
    };

    this.iObserver = new IntersectionObserver(iObserveCallback, this.options);
    const elementsWrapper = document.querySelector(this.config.wrapper);

    if (elementsWrapper) {
      const elementsBySelector = document.querySelectorAll(this.config.selector);

      if (elementsBySelector.length > 0) {
        setObserverToElements(elementsBySelector);
      }
      this.mObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.addedNodes.length > 0) {
            mutation.addedNodes.forEach((element) => {
              if (element instanceof Element || element instanceof HTMLElement) {
                const addedElements = element.querySelectorAll(this.config.selector);

                if (addedElements.length > 0) {
                  setObserverToElements(addedElements);
                }
              }
            });
          }
        });
      });

      const options = {
        childList: true,
        subtree: true,
      };

      this.mObserver.observe(elementsWrapper, options);
    }
  }

  disconnectAll() {
    if (this.iObserver) {
      this.iObserver.disconnect();
    }

    if (this.mObserver) {
      this.mObserver.disconnect();
    }
  }
}
