export const formattedCanonicalLink = (route) => {
  const { params } = route;
  const [link] = route.path.split('?');
  let canonicalLink = link;

  if (params.pageNumber) {
    canonicalLink = link.replace(`page-${params.pageNumber}`, '').slice(0, -1);
    return canonicalLink;
  }

  return canonicalLink;
};
