/**
 * Чтобы руками не проставлять полкам index,
 * ищем все полки и проставляем им индекс автоматом
 */
export const setShelfIndexAttribute = () => {
  const allShelves = document.querySelectorAll('[data-b24-shelf-name]');
  const hasShelves = allShelves?.length;
  if (hasShelves) {
    allShelves.forEach((element, index) => {
      element.setAttribute('data-b24-shelf-index', String(index + 1));
    });
  }
};
