<template>
  <div class="modals">
    <portal-target multiple name="ModalsTarget" />
  </div>
</template>

<script>
export default {
  name: 'PortalsTarget',
};
</script>
