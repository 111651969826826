import { onUnmounted } from '@nuxtjs/composition-api';

import { useDebounceFn } from '@vueuse/core';

import { analyticViewItemList } from '@/utils/analytics/analyticEvents';
import ElementsObserver from '@/utils/analytics/elementsObserver';

export const useViewedProducts = () => {
  let viewObserver = null;
  let productsViewed = [];

  const sendAnalyticProductsEvent = useDebounceFn(() => {
    analyticViewItemList(productsViewed);
    productsViewed = [];
  }, 1000);

  const sendViewedProducts = () => {
    viewObserver = new ElementsObserver({
      selector: '[data-b24-product-card]',
      wrapper: '[data-b24-analytic-wrapper]',
      event: 'product-view',
      threshold: 0.55,
    });

    viewObserver.init((payload) => {
      productsViewed.push(payload);
      sendAnalyticProductsEvent();
    });
  };

  const reInitObserve = () => {
    if (viewObserver) {
      viewObserver.disconnectAll();
      sendViewedProducts();
    }
  };

  onUnmounted(() => {
    if (viewObserver) {
      viewObserver.disconnectAll();
    }
  });

  return {
    sendViewedProducts,
    reInitObserve,
  };
};
